.card {
    padding: 16px;
}

.content {
    font-size: 12px !important;
    margin: 8px 0 8px 0;
}

.title {
    text-align: center;
    font-size: 14px !important;
    padding: 0;
    margin: 0;
}