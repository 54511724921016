.container__card {
    border-radius: 2px;
    margin-bottom: 8px;
    background-color: white;
}

.dragging {
    background-color: lightgreen;
}

