* {
    outline: none;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


#board {
    padding: 2%;
    display: flex;
    overflow: scroll;
}

.container__columns {
    display: flex;
}

#btn__add-column {
    max-width: 100px;
    padding: 10px;
    margin-left: 2%;
    border-radius: 5px;
    background: lightblue;
    font-size: 16px;
    font-weight: bold;
}
